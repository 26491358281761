import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API_PATH from "../Constants/api-path";
import { CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { notification } from 'antd';
import API_URL from '../Constants/api-path';
import ENV from '../Constants/env';
import crypto from 'crypto-js'
import { history } from '../Utils/history';

const name = 'authExpert';
const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    error: null,
    loading: false,
    success: false,
    utm: null,
};

export const email_login = createAsyncThunk(
    `${name}/email_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_PATH.EXPERT_LOGIN}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                // password: password
                password: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY)?.toString()
            }),
        });
        let login_response = await response.json();
        if (login_response?.status) {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    },
);

export const otp_login = createAsyncThunk(
    `authExpert/otp_login`,
    async ({ email, password }, { rejectWithValue }) => {
        const response = await fetch(`${API_URL.OTPLOGINEXPERT}/${email}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                otp: crypto.AES.encrypt(password, ENV.CRYPTO_JS_KEY).toString(),
            })
        });
        let login_response = await response.json()
        if (login_response?.status === true || login_response?.status === 200 || login_response?.status === "200") {
            return login_response
        } else {
            return rejectWithValue(login_response);
        }
    }
)

const slice = createSlice({
    name: 'authExpert',
    initialState: initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            localStorage.removeItem("expertData");
            localStorage.removeItem("isExpertAuth");
            return history.navigate('/expert-login');
        },
        resetState: (state) => {
            state.user = null;
            state.error = null;
            state.loading = false;
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(email_login.fulfilled, (state, action) => {
            const result = action.payload
            localStorage.setItem("isExpertAuth", true);
            localStorage.setItem("expertData", JSON.stringify(result));
            if (result?.token) {
                localStorage.setItem("lastExpertActiveTime", new Date().toString()); // Set the last active time when the user logs in
                localStorage.setItem("lastExpertLoginTime", new Date().getTime().toString()); // Set the last login time

                let redirectPath = localStorage.getItem("redirectPath");
                if (redirectPath) {
                    localStorage.removeItem("redirectPath");
                    history.navigate(redirectPath);
                } else {
                    history.navigate("/expert/dashboard");
                    notification.success({
                        message: "Login Successful",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            }

        })
        builder.addCase(email_login.pending, (state,) => {
            state.error = null;
            state.loading = true;
        })
        builder.addCase(email_login.rejected, (state, action) => {
            state.error = action?.payload?.message;
            state.loading = false;
            notification.error({
                message: action?.payload?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })

        builder.addCase(otp_login?.fulfilled, (state, action) => {
            let result = action.payload
            // result.data = JSON.parse(atob(result.data));
            // result.token = atob(result.token);
            state.loading = false;
            localStorage.setItem("isExpertAuth", true);
            localStorage.setItem("expertData", JSON.stringify(result));
            if (result?.token) {
                localStorage.setItem("lastExpertActiveTime", new Date().toString()); // Set the last active time when the user logs in
                localStorage.setItem("lastExpertLoginTime", new Date().getTime().toString()); // Set the last login time

                let redirectPath = localStorage.getItem("redirectPath");
                if (redirectPath) {
                    localStorage.removeItem("redirectPath");
                    history.navigate(redirectPath);
                } else {
                    history.navigate("/expert/dashboard");
                    notification.success({
                        message: "Login Successful",
                        icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                        style: {
                            backgroundColor: "#2ecc71",
                            color: "#fff !important",
                            border: "1px solid #52c41a",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            }

        })
        builder.addCase(otp_login?.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(otp_login?.rejected, (state, action) => {
            state.error = action?.payload?.message
            state.loading = false
            notification.error({
                message: action?.payload?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        })
    }
});

// exports

export const authExpertActions = { ...slice.actions };
export const authExpertReducer = slice.reducer;

